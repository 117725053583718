import React, { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import i18n from "../../i18n";
import { REDIRECT_URL } from "../../constants";
import { newConfiguration } from "../../model/configuration";
import { getProduct, updateProductState } from "../../model/product";
import { ProductLoading } from "../ProductLoading/component";

export const ProductRedirect = () => {
  const params = useParams();
  const productId = params?.productId;

  const navigate = useRef(useNavigate());

  //TODO create a custom hook
  useEffect(() => {
    const a = async (quoteId) => {
      try {
        const prod = await getProduct(productId);
        const config = await newConfiguration(prod);

        const upProd = updateProductState(prod, config);
        //Navigate to configurator
        navigate.current("/myconfiguration", {
          state: {
            product: upProd,
            configuration: config,
          },
        });
      } catch (error) {
        //TODO: add error handling
        window.location.href = REDIRECT_URL;
      }
    };

    if (productId !== undefined) a(productId);
  }, [productId, navigate]);

  return <ProductLoading refreshRate={0} message={i18n.t("searching")} />;
};
