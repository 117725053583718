import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-regular-svg-icons";
import { faCopy } from "@fortawesome/pro-regular-svg-icons";

import i18n from "../../i18n";
import { REDIRECT_URL } from "../../constants";
import { ConfigurationPdfLink } from "../ConfigurationPdfLink";
import { useState } from "react";

export const ConfigurationSuccessModal = (props) => {
  const [copied, setCopied] = useState(null);

  const onCloseClickHandler = () => {
    window.location.replace(REDIRECT_URL);
  };

  const onCopyClickHandler = async () => {
    try {
      await navigator.clipboard.writeText(props.config.id);
      setCopied(true);
    } catch (e) {
      console.err(e);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
      animation={false}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {i18n.t("configModalHeader")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid p-2">
          <div className="row pb-3">
            <img
              alt="configuration"
              src={props.image}
              className="modal-img"
            ></img>
          </div>
          <div className="row">
            <p>{i18n.t("configModalbody")}</p>
          </div>
          <div className="row">
            <button
              type="button"
              className="ms-button ms-button--primary col-4 mx-1"
              name="copy"
              onClick={onCopyClickHandler}
            >
              {copied ? i18n.t("copied") : i18n.t("code")}
              {!props.isReady ? (
                <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
              ) : (
                <FontAwesomeIcon className="ms-2" icon={faCopy} />
              )}
            </button>

            <ConfigurationPdfLink
              isReady={props.config}
              config={props.config}
              image={props.image}
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex justify-content-center">
          <button
            type="button"
            className="w-100 ms-button ms-button--secondary"
            name="close"
            onClick={onCloseClickHandler}
          >
            {i18n.t("close")}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
