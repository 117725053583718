import {
  Document,
  Page,
  Image,
  Text,
  View,
  StyleSheet,
} from "@react-pdf/renderer";

import { ASSET_URL } from "../../constants";
import i18n from "../../i18n";

export const ConfigurationPdf = (props) => {
  //console.log(`${ASSET_URL}/company-logo.png`);
  return (
    <Document>
      <Page
        size="A4"
        orientation="portrait"
        style={styles.page}
        producer={"Missoun"}
        creator={"Missoun"}
        title={i18n.t("yourConfig")}
      >
        <Image
          style={styles.logo}
          src={
            `${ASSET_URL}/company-logo.png?noCache=` + Math.random().toString()
          }
        />
        <Text style={styles.title}>{props.conf.name}</Text>
        <Text style={styles.price}>
          {i18n.t("totPrice")}
          {props.conf.price} {props.conf.currency}
        </Text>
        <Text style={styles.subtitle}>{i18n.t("yourConfig")}</Text>
        <View style={styles.row}>
          <View style={styles.left}>
            <Image src={props.image} style={styles.image} />
          </View>
          <View style={styles.right}>
            {props.conf.features.map((feat, index) => {
              return (
                <View key={index} style={styles.group}>
                  <Text style={styles.feature}>{feat.name}</Text>
                  {feat.selOptions.map((opt, index) => {
                    return (
                      <Text key={index} style={styles.text}>
                        {opt.name}
                      </Text>
                    );
                  })}
                </View>
              );
            })}
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.codeView}>
            <Text style={styles.codeText}>{i18n.t("configCode")} </Text>
            <Text style={styles.code}>{props.conf.id}</Text>
            <Text style={styles.codeText}>
              {i18n.t("configLink") + props.conf.id}
            </Text>
          </View>
        </View>
        <View style={styles.footerView}>
          <Text style={styles.disclaimer}>{i18n.t("disclaimer")}</Text>
          <View style={styles.footerWrap}>
            <View style={styles.footer}>
              <Text>{i18n.t("footerAddress")}</Text>
              <Text>{i18n.t("footerTel")}</Text>
              <Text>{i18n.t("footerEmail")}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#252525",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    marginTop: 40,
    fontSize: 24,
    textAlign: "left",
    fontFamily: "Helvetica-Bold",
    color: "white",
    marginLeft: 25,
  },
  subtitle: {
    marginTop: 50,
    fontSize: 20,
    textAlign: "right",
    fontFamily: "Helvetica-Bold",
    color: "white",
    marginRight: 25,
  },
  price: {
    fontSize: 14,
    textAlign: "left",
    marginTop: 5,
    fontFamily: "Helvetica",
    color: "white",
    marginLeft: 25,
  },
  logo: {
    marginTop: 25,
    marginLeft: 25,
    height: 16,
    width: 114,
  },
  image: {
    marginLeft: 25,
    width: 310,
  },
  text: {
    fontSize: 14,
    textAlign: "left",
    fontFamily: "Helvetica",
    color: "white",
    marginTop: 5,
  },
  codeText: {
    fontSize: 11,
    fontFamily: "Helvetica",
    color: "white",
    textAlign: "right",
    marginRight: 25,
  },
  code: {
    marginTop: 8,
    marginBottom: 8,
    fontSize: 14,
    fontFamily: "Helvetica-Bold",
    color: "white",
    textAlign: "right",
    marginRight: 25,
  },
  codeView: {
    marginTop: 80,
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  group: {
    marginBottom: 15,
    marginLeft: 15,
  },
  feature: {
    fontSize: 12,
    textAlign: "left",
    color: "white",
    fontFamily: "Helvetica-Bold",
  },

  row: {
    marginTop: 25,
    flex: 1,
    flexDirection: "row",
    flexGrow: 1,
  },

  left: {
    width: "60%", //<- working alternative
  },

  right: {
    width: "40%", //<- working alternative
    borderTop: 1,
    borderTopColor: "white",
    borderTopStyle: "solid",
    paddingTop: 20,
    marginLeft: 10,
    marginRight: 15,
  },

  footerView: {
    marginLeft: 25,
    marginRight: 25,
    position: "absolute",
    bottom: 30,
    alignSelf: "center",
    width: "90%",
  },
  disclaimer: {
    fontSize: 10,
    fontFamily: "Helvetica",
    color: "white",
    textAlign: "justify",
    paddingBottom: 10,
  },

  footerWrap: {
    borderTop: 1,
    borderTopColor: "white",
    borderTopStyle: "solid",
    position: "relative",
  },
  footer: {
    alignSelf: "center",
    fontSize: 10,
    color: "gray",
    width: "80%",
    paddingTop: 10,
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
