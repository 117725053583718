import React, { useEffect, useState } from "react";
import { Product } from "./Product/Product";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

import "./component.css";

export const ProductSlider = ({ group, handleClickSelect }) => {
  const [current, setCurrent] = useState(0);
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const length = group?.products?.length;

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const nextProduct = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevProduct = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(group.products) || group.products.length <= 0) {
    return null;
  }

  function getWindowSize() {
    const { innerWidth } = window;
    return { innerWidth };
  }

  return (
    <div className="product-slider">
      <div className="product-title">{group.description}</div>
      <div className="product-container">
        <FaAngleLeft className="left-arrow" onClick={prevProduct} />
        <div className="product-wrapper">
          {group.products.map((product, index) => {
            return windowSize.innerWidth <= 576 ? (
              <Product
                product={product}
                key={index}
                index={index}
                current={current}
                onClickSelect={handleClickSelect}
              />
            ) : (
              index === current && (
                <Product
                  product={product}
                  key={index}
                  index={index}
                  current={current}
                  onClickSelect={handleClickSelect}
                />
              )
            );
          })}
        </div>
        <FaAngleRight className="right-arrow" onClick={nextProduct} />
      </div>
      <div className="product-nav-dots">
        {group.products.map((_, index) => {
          return (
            <div
              className={index === current ? "dots active" : "dots"}
              onClick={() => setCurrent(index)}
              key={index}
            ></div>
          );
        })}
      </div>
    </div>
  );
};
