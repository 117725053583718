import { pdf } from "@react-pdf/renderer";
import { ConfigurationPdf } from "../ConfigurationPdf/component";
import { getProduct, updateProductState } from "../../model/product";

const generateConfigurationPdf = async (config, image) => {
  const pdfData = await generateConfigurationPdfData(config);

  const blob = await pdf(
    <ConfigurationPdf conf={pdfData} image={image} />
  ).toBlob();

  return URL.createObjectURL(blob);
  //window.location.href = pdfUrl;
};

export const generateConfigurationPdfData = async (config) => {
  const prod = await getProduct(config.productId);
  const updatedProd = updateProductState(prod, config);
  //console.log(updatedProd);

  let pdfData = {
    id: config.id,
    name: updatedProd.name,
    price: updatedProd?.totalAmount,
    currency: updatedProd?.listPrice[0]?.currency,
    features: [],
  };
  updatedProd.feats.forEach((feat) => {
    const selOptions = feat.opts.filter((opt) => opt.status === "Selected");
    if (selOptions) {
      pdfData.features.push({ name: feat.name, selOptions });
    }
  });

  return Promise.resolve(pdfData);
};

export default generateConfigurationPdf;
