import generateConfigurationPdf from "./utils";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileArrowDown,
  faSpinnerThird,
} from "@fortawesome/pro-regular-svg-icons";

import i18n from "../../i18n";

export const ConfigurationPdfLink = ({ config, image, isReady }) => {
  const [url, setUrl] = useState(null);
  useEffect(() => {
    const getPdfData = async (c) => {
      const url = await generateConfigurationPdf(config, image);
      setUrl(url);
    };
    if (isReady) getPdfData(config);
  }, [config, image, isReady]);

  return (
    <div className="button-container col-4 mx-1 ms-button ms-button--primary">
      <a
        className="w-100 button"
        href={url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {!url ? i18n.t("creating") : i18n.t("download")}
        {!url ? (
          <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
        ) : (
          <FontAwesomeIcon className="ms-2" icon={faFileArrowDown} />
        )}
      </a>
    </div>
  );
};
