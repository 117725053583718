import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";

import i18n from "../../i18n";

import { ConfiguratorHeader } from "../ConfiguratorHeader";
import { Viewer } from "../Viewer";
import { ProductLoading } from "../ProductLoading";
import { ProductConfiguration } from "../ProductConfiguration/component";
import { useLocation } from "react-router-dom";

export const Configurator = () => {
  const [currentLanguage, setcurrentLanguage] = useState(i18n.language);

  const [isLoading, setIsLoading] = useState(true);
  const [viewerReady, setViewerReady] = useState(false);
  const apiUtilsRef = useRef(null);

  const { state } = useLocation();

  const handleLanguageClick = (lng) => {
    setcurrentLanguage(lng);
  };

  const handleViewerReady = useCallback(() => setViewerReady(true), []);
  const handleViewerInit = useCallback((api) => {
    apiUtilsRef.current = api;
  }, []);

  const viewer = useMemo(() => {
    return (
      <Viewer
        model={state.product.modelId}
        onViewerInit={handleViewerInit}
        onModelReady={handleViewerReady}
      />
    );
  }, [state.product, handleViewerInit, handleViewerReady]);

  useEffect(() => {
    if (viewerReady) {
      if (apiUtilsRef !== null) {
        apiUtilsRef.current.enableDebugLogging = false;
        apiUtilsRef.current.onViewerReady();
        setIsLoading(false);
      }
    }
  }, [apiUtilsRef, viewerReady]);

  console.log("Render Configurator");
  return (
    <React.StrictMode>
      <>
        <ConfiguratorHeader
          currentLanguage={currentLanguage}
          onClick={handleLanguageClick}
        />
        <section className="configurator-main">
          <div className="row g-0 h-100">
            <div className="col-12 col-md-ls col-lg configurator-viewer">
              {viewer}
            </div>
            <div className="col-12 col-md-4-ls col-lg-4 configurator-panel">
              <ProductConfiguration
                prod={state.product}
                configuration={state.configuration}
                apiRef={apiUtilsRef}
                viewerReady={viewerReady}
              />
            </div>
          </div>
          {isLoading && (
            <ProductLoading refreshRate={600} message={i18n.t("loading")} />
          )}
        </section>
      </>
    </React.StrictMode>
  );
};
