import React from "react";
import "./component.css";

export const ProductHeader = (props) => {
  return (
    <div className="d-flex align-items-center ms-panel-header">
      <h3 className="product-header__name">{props.name}</h3>
    </div>
  );
};
