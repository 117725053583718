//import logo from "./logo.svg";

import React from "react";
import { Route, Routes } from "react-router-dom";

import { ProductGroup } from "./components/ProductGroup";

import { Home } from "./pages/Home";

import { ConfigurationRedirect } from "./components/ConfigurationRedirect";
import { ProductRedirect } from "./components/ProductRedirect/component";
import { Configurator } from "./components/Configurator";

import { REDIRECT_URL } from "./constants";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route
          path="products-groups/:productGroupId"
          element={<ProductGroup />}
        />
        <Route path="products/:productId" element={<ProductRedirect />} />
        <Route
          path="configurations/:configurationId"
          element={<ConfigurationRedirect />}
        />
        <Route path="myconfiguration" element={<Configurator />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );

  function NotFound() {
    window.location.href = REDIRECT_URL;
    return null;
  }
}
export default App;
