import { urlGetProduct } from "../helpers/apis-urls";
import { fetchData, HttpVerb } from "./utils";

async function getProduct(id) {
  const prod = await fetchData(urlGetProduct(id), HttpVerb.GET);
  const initializedProduct = initializeProduct(prod);
  return Promise.resolve(initializedProduct);
}

const initializeProduct = (product) => {
  const prod = structuredClone(product);
  if (prod !== undefined) {
    for (let i = 0; i < prod.feats.length; i++) {
      for (let j = 0; j < prod.feats[i].opts.length; j++) {
        prod.feats[i].opts[j].status = product.feats[i].opts[j].isDefault
          ? "Selected"
          : "Initial";
      }
    }
  }
  return prod;
};

/*
const sortProductFeatures = (product) => {
  const prod = structuredClone(product);
  prod.feats = prod.feats.sort((a, b) => a.seq - b.seq);
  prod.feats.forEach((feat, index) => {
    prod.feats[index].opts = feat.opts.sort((a, b) => a.seq - b.seq);
  });
  return prod;
};
*/

const updateProductState = (product, configuration) => {
  const prod = structuredClone(product);
  let optPrices = [];
  if (configuration !== undefined && prod !== undefined) {
    configuration.features.forEach((feat) => {
      const prodFeat = prod.feats.find((f) => f.id === feat.code);
      prodFeat.state = feat.state;
      feat.options.forEach((opt) => {
        const prodOpt = prodFeat.opts.find((o) => o.id === opt.code);
        if (prodOpt) {
          prodOpt.status = opt.state;
          if (prodOpt.listPrice.length && opt.state === "Selected")
            optPrices.push(prodOpt.listPrice[0].amount);
        }
      });
    });

    if (prod.priceOn) {
      prod.totalAmount = optPrices.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        prod?.listPrice[0]?.amount
      );
    }
  }
  return prod;
};

export { getProduct, updateProductState };
