import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import i18n from "../../i18n";

import { getConfiguration } from "../../model/configuration";
import { getProduct, updateProductState } from "../../model/product";
export const ConfigurationLoader = () => {
  const [quoteId, setQuoteId] = useState(null);
  const [notFound, setNotFound] = useState(false);

  const navigate = useNavigate();

  const handleQuoteInputOnChange = (event) => {
    const value = event.target.value;
    setQuoteId(value);
    setNotFound(false);
  };

  async function handleOnLoadClick() {
    try {
      const config = await getConfiguration(quoteId);
      const prod = await getProduct(config.productId);

      const upProd = updateProductState(prod, config);
      //Navigate to configurator
      navigate("/myconfiguration", {
        state: {
          product: upProd,
          configuration: config,
        },
      });
    } catch (error) {
      setNotFound(true);
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-center">
        <input
          className="w-100 ms-input"
          type="text"
          name="productId"
          onChange={handleQuoteInputOnChange}
          placeholder={"Insert your configuration code"}
          autoComplete="off"
        />
        <button
          type="button"
          className="ms-button ms-button--secondary px-3 ms-2 "
          name="Load"
          onClick={handleOnLoadClick}
        >
          {i18n.t("load")}
        </button>
      </div>
      {notFound && (
        <div>
          <span className={"ms-text--error"}>{i18n.t("codeNotValid")}</span>
        </div>
      )}
    </div>
  );
};
