import React from "react";

import { ConfigurationLoader } from "../components/ConfigurationLoader/index";

const assetUrl =
  process.env.REACT_APP_MS_ASSET_URL !== undefined
    ? process.env.REACT_APP_MS_ASSET_URL
    : `https://d18zp8aace0t2d.cloudfront.net`;

export const Home = () => {
  return (
    <div className="containerfluid vh-100">
      <div className="row h-100 m-0 justify-content-center auto">
        <div className="col-12 pb-5 d-flex justify-content-center align-items-end">
          <img
            className="logo logo-bigbox"
            src={`${assetUrl}/company-logo.png`}
            alt="Company logo"
          />
        </div>
        <div className="col-xs-10 col-sm-8 col-md-8 col-lg-6">
          <ConfigurationLoader />
        </div>
      </div>
    </div>
  );
};
