const BASE_URL =
  process.env.REACT_APP_MS_QUOTE_API !== undefined
    ? process.env.REACT_APP_MS_QUOTE_API
    : `https://dev-missoun.api.configurator.dev.missoun.com/v1`;

export const urlGetProduct = (id) => url(`/products/${id}`);

export const urlGetConfiguration = (id) => url(`/configurations/${id}`);

export const urlPostConfiguration = (productId) =>
  url(`/products/${productId}/configurations`);

export const urlPutConfiguration = (productId, configId) =>
  url(`/products/${productId}/configurations/${configId}`);

const url = (urlFragment) => {
  let url = new URL(BASE_URL + urlFragment);
  return url.href;
};
