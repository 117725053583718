import React from "react";

import i18n from "../../i18n";

import Accordion from "react-bootstrap/Accordion";
import { FeatureTypes } from "../FeatureTypes/index";
import "./component.css";

const getSelectedOpt = (feat) => {
  const opt = feat.isSingle
    ? feat.opts.find((opt) => opt.status === "Selected")
    : undefined;

  return opt;
};

const FeatureAccordionHeader = (props) => {
  /*const prodRequiredTag = props?.feature?.isReq ? (
    <span className="accordion-header__required">(required)</span>
  ) : null;*/

  const prodOptionalTag = !props?.feature?.isReq ? (
    <span className="accordion-header__optional">{i18n.t("optional")}</span>
  ) : null;

  const optSelected = getSelectedOpt(props.feature);

  return (
    <Accordion.Header>
      <div className="accordion-header w-100">
        <div className="d-flex align-items-center accordion-header__feature ">
          <h4 className="accordion-header__name">{props?.feature?.name}</h4>
          {prodOptionalTag}
        </div>
        {props?.feature?.state === "SelectedWithError" && (
          <div>
            <span className={"ms-text--error"}>{i18n.t("selectionError")}</span>
          </div>
        )}
        <div className="d-flex flex-row justify-content-between align-items-center">
          <h5 className="accordion-header__option-selected">
            {optSelected?.name}
          </h5>
          <div className="accordion-header__option-price">
            <span>
              {optSelected?.listPrice[0]?.amount}{" "}
              {optSelected?.listPrice[0]?.currency}
            </span>
          </div>
        </div>
      </div>
    </Accordion.Header>
  );
};

const FeatureAccordionItem = (feature, handleOptionSelectionChange) => {
  // component does exist
  //TODO remove from from accordion body
  return (
    <Accordion.Item
      className="accordion-feature"
      key={feature.id}
      eventKey={feature.id}
    >
      <FeatureAccordionHeader feature={feature}></FeatureAccordionHeader>
      <Accordion.Body>
        {React.createElement(FeatureTypes[feature?.type.toLowerCase()], {
          feature: feature,
          onOptionSelectionChange: handleOptionSelectionChange,
        })}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export const ProductFeaturesAccordion = (props) => {
  const handleOptionSelectionChange = (event, number) => {
    props.onOptionSelectionChange(event, number);
  };
  return (
    <Accordion defaultActiveKey="0">
      {props.features.map((feature) =>
        FeatureAccordionItem(feature, handleOptionSelectionChange)
      )}
    </Accordion>
  );
};
