import React, { useState } from "react";

import { Color } from "./Color";
import "./ColorSelector.css";

export const ColorSelector = ({ feature, onOptionSelectionChange }) => {
  const colorGroup = feature?.groups?.map((colorGroup) => {
    //return colorGroup.id;
    return colorGroup;
  });

  let newTabs = [...new Set(colorGroup)];

  if (newTabs) newTabs = newTabs.sort((a, b) => a.seq - b.seq);

  const [activeTab, setActiveTab] = useState(
    newTabs !== undefined ? newTabs[0] : null
  );

  const handleSwitchTab = (tab) => {
    setActiveTab(tab);
  };
  if (colorGroup.length !== 0) {
    return (
      <div className="color-palette">
        <div className="color-groups">
          <ul className="nav">
            {newTabs?.map((tab, i) => {
              return (
                <li
                  key={tab.id}
                  className={activeTab.id === tab.id ? "active" : ""}
                  onClick={() => handleSwitchTab(tab)}
                >
                  {tab.name}
                </li>
              );
            })}
          </ul>
          <div className="d-flex flex-wrap color-options">
            {feature?.opts?.map((opt) => {
              if (opt.group === activeTab.id) {
                return (
                  <Color
                    key={opt.id}
                    option={opt}
                    onOptionSelectionChange={onOptionSelectionChange}
                  />
                );
              } else return null;
            })}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="p-0 color-palette">
        <div className="d-flex flex-wrap color-options">
          {feature?.opts?.map((option) => {
            return (
              <Color
                key={option.id}
                option={option}
                onOptionSelectionChange={onOptionSelectionChange}
              />
            );
          })}
        </div>
      </div>
    );
  }
};

/*
<ul className="nav ms-tabs">
          {newTabs?.map((tab, i) => {
            return (
              <li
                key={tab.id}
                className={"ms-tab" + activeTab.id === tab.id ? "active" : ""}
                onClick={() => handleSwitchTab(tab)}
              >
                {tab.name}
              </li>
            );
          })}
        </ul>
        
        
        <div className="color-options">
                  {feature?.opts?.map((opt) => {
                    if (opt.group === activeTab.id || activeTab === "all") {
                      return (
                        <Color
                          option={opt}
                          onOptionSelectionChange={onOptionSelectionChange}
                        />
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
        */
