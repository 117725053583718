import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import i18n from "../../i18n";

import { countries } from "./utils";
import { ConfigurationSuccessModal } from "../ConfigurationSuccessModal";
import "./component.css";

export const ContactForm = ({ configuration, onCancel, apiRef }) => {
  const dropdown_countries = Object.values(countries).map((country, index) => (
    <option key={index}>{country}</option>
  ));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const configRef = useRef(configuration);
  const [contact, setContact] = useState(null);
  const [configReady, setConfigReady] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [image, setImage] = useState(null);

  useEffect(() => {
    apiRef.current.getScreenShot((img) => {
      setImage(img);
    });
  }, [apiRef]);

  useEffect(() => {
    const saveConfiguration = async (config, contact) => {
      //const s = Date.now();

      config.contact = contact;

      let url =
        process.env.REACT_APP_MS_QUOTE_API !== undefined
          ? process.env.REACT_APP_MS_QUOTE_API
          : `https://dev-missoun.api.configurator.dev.missoun.com/v1`;
      url += `/configurations`;

      const res = await fetch(url, {
        method: "POST",
        headers: { "content-Type": "application/json" },
        body: JSON.stringify(config),
      });

      //ritorna id
      const c = await res.json();
      config.id = c.id;
      //console.log("validate configuration duration:", Date.now() - s, "ms");
    };

    if (contact !== null) {
      setModalShow(true);
      saveConfiguration(configRef.current, contact).then(() => {
        setConfigReady(true);
      });
    }
  }, [contact]);

  return (
    <>
      <form
        key="contactForm"
        className="contact-form ms-scrollbar"
        onSubmit={handleSubmit((data) => {
          setContact(data);
        })}
      >
        <div className="contact-form__panel">
          <div className="container-fluid h-100 px-0">
            <div className="row g-0 contact-form__details ms-scrollbar">
              <div className="contact-form__inputs">
                <div className="col-12 contact-form__input">
                  <div className="d-flex align-items-center">
                    <label htmlFor="inputFirstName" className="form-label">
                      {i18n.t("name")}
                    </label>
                    <span className="ms-input--required">*</span>
                  </div>
                  <input
                    type="text"
                    className="ms-input"
                    id="inputFirstName"
                    {...register("firstName", {
                      required: i18n.t("requiredField"),
                    })}
                    autoComplete="off"
                  />
                  {errors.firstName && (
                    <p className="ms-text--error pt-1">
                      {errors?.firstName?.message}
                    </p>
                  )}
                </div>
                <div className="col-12 contact-form__input">
                  <div className="d-flex align-items-center">
                    <label htmlFor="inputLastName" className="form-label">
                      {i18n.t("surname")}
                    </label>
                    <span className="ms-input--required">*</span>
                  </div>
                  <input
                    type="text"
                    className="ms-input"
                    id="inputLastName"
                    {...register("lastName", {
                      required: i18n.t("requiredField"),
                    })}
                    autoComplete="off"
                  />
                  {errors.lastName && (
                    <p className="ms-text--error pt-1">
                      {errors?.lastName?.message}
                    </p>
                  )}
                </div>
                <div className="col-12 contact-form__input">
                  <div className="d-flex align-items-center">
                    <label htmlFor="inputEmail" className="form-label">
                      {i18n.t("email")}
                    </label>
                    <span className="ms-input--required">*</span>
                  </div>
                  <input
                    type="email"
                    className="ms-input"
                    id="inputEmail"
                    {...register("email", {
                      required: i18n.t("requiredField"),
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: i18n.t("invalidField"),
                      },
                    })}
                  />
                  {errors.email && (
                    <p className="ms-text--error pt-1">
                      {errors?.email?.message}
                    </p>
                  )}
                </div>
                <div className="col-12 contact-form__input">
                  <label htmlFor="inputTelephone" className="form-label">
                    {i18n.t("telephone")}
                  </label>
                  <input
                    type="text"
                    className="ms-input"
                    id="inputTelephone"
                    {...register("telephone", {
                      pattern: {
                        value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\\./0-9]*$/,
                        message: i18n.t("invalidField"),
                      },
                    })}
                    autoComplete="off"
                  />
                  {errors.telephone && (
                    <p className="ms-text--error pt-1">
                      {errors?.telephone?.message}
                    </p>
                  )}
                </div>
                <div className="col-12 contact-form__input">
                  <div className="d-flex align-items-center">
                    <label htmlFor="inputCity" className="form-label">
                      {i18n.t("city")}
                    </label>
                    <span className="ms-input--required">*</span>
                  </div>
                  <input
                    type="text"
                    className="ms-input"
                    id="inputCity"
                    {...register("city", { required: i18n.t("requiredField") })}
                    autoComplete="off"
                  />
                  {errors.city && (
                    <p className="ms-text--error pt-1">
                      {errors?.city?.message}
                    </p>
                  )}
                </div>
                <div className="col-12 contact-form__input">
                  <div className="d-flex align-items-center">
                    <label htmlFor="inputState" className="form-label">
                      {i18n.t("country")}
                    </label>
                    <span className="ms-input--required">*</span>
                  </div>
                  <select
                    id="inputState"
                    className="ms-select"
                    {...register("country", {
                      required: i18n.t("requiredField"),
                    })}
                    defaultValue={"Italy"}
                  >
                    <option disabled>{i18n.t("choose")}</option>
                    {dropdown_countries}
                  </select>
                  {errors.country && (
                    <p className="ms-text--error pt-1">
                      {errors?.country?.message}
                    </p>
                  )}
                </div>
                <div>
                  <div className="col-12 contact-form__input">
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        className="ms-checkbox"
                        id="inputAgree"
                        {...register("acceptTerms", {
                          required: i18n.t("termsError"),
                        })}
                      />
                      <label className="contact-form__privacy">
                        {i18n.t("termsText")}
                        <a
                          className="contact-form__href"
                          href={i18n.t("termsHref")}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {i18n.t("terms")}
                        </a>
                      </label>
                    </div>
                    {errors.agree && (
                      <p className="ms-text--error pt-1">
                        {errors?.agree?.message}
                      </p>
                    )}
                  </div>
                  <div className="col-12 contact-form__input d-flex">
                    <input
                      type="checkbox"
                      className="ms-checkbox"
                      id="inputPolicy"
                      {...register("consent")}
                    />
                    <label className="contact-form__privacy">
                      {i18n.t("privacyText")}
                      <a
                        className="contact-form__href"
                        href={i18n.t("privacyHref")}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {i18n.t("privacy")}
                      </a>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0 ms-panel-footer">
              <div className="container-fluid h-100">
                <div className="row h-100 align-items-center px-3">
                  <div className="col-3 px-2">
                    <button
                      type="button"
                      className="w-100 ms-button ms-button--secondary"
                      name="back"
                      onClick={onCancel}
                    >
                      {i18n.t("back")}
                    </button>
                  </div>
                  <div className="col-9 px-2">
                    <input
                      type="submit"
                      className="w-100 ms-button ms-button--primary"
                      value={i18n.t("save")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ConfigurationSuccessModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        config={configReady ? configRef.current : undefined}
        image={image}
        isReady={configReady}
      />
    </>
  );
};
