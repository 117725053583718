export const HttpVerb = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export async function fetchData(url, verb) {
  const fetchInit = {
    method: verb,
    headers: { "content-Type": "application/json" },
  };
  try {
    //Fetch quote
    const res = await fetch(url, fetchInit);
    if (!res.ok) {
      throw new Error(res.status);
    }
    const data = await res.json();
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
}
