import React from "react";

import i18n from "../../../i18n";

import "./Product.css";

export const Product = ({ product, onClickSelect }) => {
  return (
    <div className={`product-body`}>
      <div className="image">
        <img src={product.img} alt={product.name} />
      </div>
      <div className="details">
        <h2 className="product-name">{product.name}</h2>
        <h3 className="product-pricetag">
          {product.listPrice[0].amount} {product.listPrice[0].currency}
        </h3>
        <p className="description">{product.desc}</p>
        <button
          className={`ms-button ms-button--primary`}
          onClick={() => onClickSelect(product)}
        >
          {i18n.t("select")}
        </button>
      </div>
    </div>
  );
};
