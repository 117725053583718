export const updateTexture = (apiRef, textureUrl) => {
  apiRef.current.getTextureList((err, textures) => {
    if (!err) {
      if (textures.lenght > 1)
        //console.log("Texture lenght is: " + textures.lenght); //throw an exception it is not a valid state
        apiRef.current.updateTexture(
          textureUrl,
          textures[0].uid,
          function (err, textureUid) {
            if (!err) {
              window.console.log("Replaced texture with: ", textureUrl);
            } else {
              console.error("error: " + textureUrl);
              //console.err(err); // throw an exception
            }
          }
        );
    }
  });
};

export function SketchfabApiUtils(urlIDRef, clientRef, onReady) {
  //
  //Sketchfab-Viewer-API-Utility https://github.com/shaderbytes/Sketchfab-Viewer-API-Utility.git
  //
  let classScope = this;
  this.api = null;

  this.clientInitObject = {
    autostart: 1, //Setting to 1 will make the model load immediately once the page is ready, rather than waiting for a user to click the Play button.
    camera: 0, //Setting to 0 will skip the initial animation that occurs when a model is loaded, and immediately show the model in its default position.
    dnt: 1,
    preload: 1, //Setting to 1 will force all resources (textures) to download before the scene is displayed.
    ui_stop: 0, //Setting to 0 will hide the "Disable Viewer" button in the top right so that users cannot stop the 3D render once it is started.
    ui_animations: 0, //Setting to 0 will hide the animation menu and timeline.
    ui_annotations: 0, //Setting to 0 will hide the Annotation menu.
    ui_controls: 0, //Setting to 0 will hide all the viewer controls at the bottom of the viewer
    ui_help: 0, //Setting to 0 will hide the Help button.
    ui_hint: 0, //Setting to 0 will always hide the viewer hint animation
    ui_infos: 0, //Setting to 0 will hide the model info bar at the top of the viewer.
    ui_inspector: 0, //Setting to 0 will hide the inspector button. It will also prevent the Inspector from opening.
    ui_loading: 0, //Setting to 0 will hide the viewer loading bars.
    ui_settings: 0, //Setting to 0 will hide the Settings button.
    ui_sound: 0, //Setting to 0 will hide the Sound button.
    ui_start: 0, //Setting to 0 will hide the start/play button.
    ui_watermark: 0, //Setting to 0 remove the Sketchfab logo watermark.
  }; //if you want any default init options hard coded just add them here
  this.client = clientRef;

  //this.iframe = iframeRef;
  this.urlID = urlIDRef;

  this.materialHash = {};
  this.materialPreprocessCompleted = false;
  this.sceneTexturesPreprocessCompleted = false;

  this.eventListeners = {};
  this.onReady = onReady;

  //materialChannelProperties
  this.AOPBR = "AOPBR";
  this.AlbedoPBR = "AlbedoPBR";
  this.BumpMap = "BumpMap";
  this.CavityPBR = "CavityPBR";
  this.DiffuseColor = "DiffuseColor";
  this.DiffuseIntensity = "DiffuseIntensity";
  this.DiffusePBR = "DiffusePBR";
  this.EmitColor = "EmitColor";
  this.GlossinessPBR = "GlossinessPBR";
  this.MetalnessPBR = "MetalnessPBR";
  this.NormalMap = "NormalMap";
  this.Opacity = "Opacity";
  this.RoughnessPBR = "RoughnessPBR";
  this.SpecularColor = "SpecularColor";
  this.SpecularF0 = "SpecularF0";
  this.SpecularHardness = "SpecularHardness";
  this.SpecularPBR = "SpecularPBR";
  this.ClearCoat = "ClearCoat";
  this.ClearCoatNormalMap = "ClearCoatNormalMap";
  this.ClearCoatRoughness = "ClearCoatRoughness";
  this.Matcap = "Matcap";
  this.SubsurfaceScattering = "SubsurfaceScattering";
  this.SubsurfaceTranslucency = "SubsurfaceTranslucency";

  this.textureLoadingCount = 0;
  this.gamma = 2.4;

  this.textureCache = {};
  this.materialsUIDPending = {};

  this.EVENT_TEXTURE_APPLIED = "event_texture_applied";
  //--------------------------------------------------------------------------------------------------------------------------

  this.create = function () {
    //classScope.client = window.Sketchfab(classScope.iframe); //new Sketchfab(classScope.iframe);

    classScope.clientInitObject.success = classScope.onClientInit;
    classScope.clientInitObject.error = classScope.onClientError;
    classScope.client.init(classScope.urlID, classScope.clientInitObject);
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.onClientError = function () {
    console.error(
      'a call to "init()" on the sketchfab client object has failed'
    );
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.onClientInit = function (apiRef) {
    classScope.api = apiRef;
    classScope.api.addEventListener("viewerready", classScope.onViewerReady);
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.onViewerReady = function () {
    //prepare data for ease of use
    //for each call into the api that gets used for preprocesing a flag should be created which can be validated to decide that the
    //utility has finished all preprocessing
    classScope.api.getMaterialList(classScope.generateMaterialHash);
    //classScope.api.getSceneGraph(classScope.generateNodeHashRecursive);
    //classScope.api.getAnnotationList(classScope.generateAnnotationControls);
    //classScope.api.getAnimations(classScope.generateAnimationControls);
    classScope.api.getTextureList(classScope.getSceneTextures);
    //possible other calls here ...
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.getSceneTextures = function (err, textures) {
    if (err) {
      console.error("Error when calling getSceneTextures");
      return;
    }
    if (classScope.enableDebugLogging) {
      console.log("textures listing");
      console.log(textures);
    }
    for (var i = 0; i < textures.length; i++) {
      var UIDKey = textures[i].uid; //textures[i].name.split(".")[0];
      classScope.textureCache[UIDKey] = textures[i].uid;
    }

    classScope.sceneTexturesPreprocessCompleted = true;
    classScope.validateUtilGenerationPreprocess();
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.validateUtilGenerationPreprocess = function () {
    //validate all used preprocess flags
    if (
      classScope.materialPreprocessCompleted
      //&&
      //classScope.nodePreprocessCompleted &&
      //classScope.annotationPreprocessCompleted &&
      //classScope.animationPreprocessCompleted &&
      //classScope.sceneTexturesPreprocessCompleted
    ) {
      classScope.isInitialized = true;
      classScope.dispatchEvent(classScope.EVENT_INITIALIZED, true);
    }
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.dispatchEvent = function (eventName, eventObject) {
    var eventArray = classScope.eventListeners[eventName];
    if (eventArray !== null && eventArray !== undefined) {
      for (var i = 0; i < eventArray.length; i++) {
        eventArray[i](eventObject);
      }
    }

    classScope.onReady();
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.generateMaterialHash = function (err, materials) {
    if (err) {
      console.error("Error when calling getMaterialList");
      return;
    }
    if (classScope.enableDebugLogging) {
      console.log("materials listing");
    }
    for (var i = 0; i < materials.length; i++) {
      classScope.materialHash[materials[i].id] = materials[i];
      if (classScope.enableDebugLogging) {
        console.log("name: " + materials[i].name);
      }
    }
    classScope.materialPreprocessCompleted = true;
    classScope.validateUtilGenerationPreprocess();
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.getMaterialObject = function (materialId) {
    // Usage!
    var materialObjectRef = classScope.materialHash[materialId];
    if (materialObjectRef === null || materialObjectRef === undefined) {
      console.log(materialId);
      console.error(
        "a call to getMaterialObject using material id " +
          materialId +
          " has failed , no such material found"
      );
      return null;
    }

    return materialObjectRef;
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.getChannelObject = function (materialObjectRef, channelName) {
    var channelObjectRef = materialObjectRef.channels[channelName];
    if (channelObjectRef === null || channelObjectRef === undefined) {
      console.error(
        "a call to getChannelObject using channelName name " +
          channelName +
          " has failed , no such channelName found"
      );
      return null;
    }
    return channelObjectRef;
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.setColor = function (
    materialId,
    channelName,
    channelPropertyName,
    hex,
    performCacheReset
  ) {
    channelPropertyName = channelPropertyName || "color";
    var propertyCacheKey = channelPropertyName + "cached";

    performCacheReset = performCacheReset || false;
    var materialObjectRef = classScope.getMaterialObject(materialId);
    if (materialObjectRef !== null && materialObjectRef !== undefined) {
      var channelObjectRef = classScope.getChannelObject(
        materialObjectRef,
        channelName
      );
      if (channelObjectRef !== null && channelObjectRef !== undefined) {
        if (performCacheReset) {
          if (
            channelObjectRef[propertyCacheKey] !== undefined &&
            channelObjectRef[propertyCacheKey] !== null
          ) {
            channelObjectRef[channelPropertyName][0] =
              channelObjectRef[propertyCacheKey][0];
            channelObjectRef[channelPropertyName][1] =
              channelObjectRef[propertyCacheKey][1];
            channelObjectRef[channelPropertyName][2] =
              channelObjectRef[propertyCacheKey][2];
            classScope.api.setMaterial(materialObjectRef);
            return;
          } else {
            if (classScope.enableDebugLogging) {
              console.log(
                "a call to reset a color has been ignored since the color has not changed"
              );
            }
            return;
          }
        }

        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, function (m, r, g, b) {
          return r + r + g + g + b + b;
        });

        if (
          channelObjectRef[channelPropertyName] === null ||
          channelObjectRef[channelPropertyName] === undefined
        ) {
          channelObjectRef[channelPropertyName] = [1, 1, 1];
        }

        //since texture and color cannot exist at the same time in the sketchfab API
        //test for texture and remove if needed.
        if (channelObjectRef.texture) {
          channelObjectRef.texture = null;
          delete channelObjectRef.texture;
        }

        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        if (
          channelObjectRef[propertyCacheKey] === undefined ||
          channelObjectRef[propertyCacheKey] === null
        ) {
          channelObjectRef[propertyCacheKey] = [];
          channelObjectRef[propertyCacheKey][0] =
            channelObjectRef[channelPropertyName][0];
          channelObjectRef[propertyCacheKey][1] =
            channelObjectRef[channelPropertyName][1];
          channelObjectRef[propertyCacheKey][2] =
            channelObjectRef[channelPropertyName][2];
        }

        channelObjectRef[channelPropertyName][0] = classScope.srgbToLinear(
          parseInt(result[1], 16) / 255
        );
        channelObjectRef[channelPropertyName][1] = classScope.srgbToLinear(
          parseInt(result[2], 16) / 255
        );
        channelObjectRef[channelPropertyName][2] = classScope.srgbToLinear(
          parseInt(result[3], 16) / 255
        );
        classScope.api.setMaterial(materialObjectRef);
      }
    }
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.resetColor = function (materialName, channelName, channelPropertyName) {
    classScope.setColor(
      materialName,
      channelName,
      channelPropertyName,
      "",
      true
    );
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.linearToSrgb = function (c) {
    var v = 0.0;
    if (c < 0.0031308) {
      if (c > 0.0) v = c * 12.92;
    } else {
      v = 1.055 * Math.pow(c, 1.0 / classScope.gamma) - 0.055;
    }
    return v;
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.srgbToLinear = function (c) {
    var v = 0.0;
    if (c < 0.04045) {
      if (c >= 0.0) v = c * (1.0 / 12.92);
    } else {
      v = Math.pow((c + 0.055) * (1.0 / 1.055), classScope.gamma);
    }
    return v;
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.showNode = (id) => {
    if (!isNaN(parseInt(id))) {
      classScope.api.show(id, function (err) {
        if (err) console.error(err, id);
      });
    }
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.hideNode = (id) => {
    if (!isNaN(parseInt(id))) {
      classScope.api.hide(id, function (err) {
        if (err) console.error(err, id);
      });
    }
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.setTexturePropertiesActual = function (
    channelObjectRef,
    textureObjectDefaults
  ) {
    if (
      channelObjectRef.texture !== null &&
      channelObjectRef.texture !== undefined
    ) {
      for (var prop in textureObjectDefaults) {
        channelObjectRef.texture[prop] = textureObjectDefaults[prop];
      }
    }
  };

  //--------------------------------------------------------------------------------------------------------------------------

  //--------------------------------------------------------------------------------------------------------------------------

  this.applyMaterialUIDPending = function (UIDKey) {
    if (UIDKey !== null && UIDKey !== undefined && UIDKey !== "") {
      var storage = classScope.materialsUIDPending[UIDKey];
      var uid = classScope.textureCache[UIDKey];
      if (storage !== null && storage !== undefined) {
        for (var i = 0; i < storage.length; i++) {
          var ob = storage[i];
          var materialName = ob.materialName;
          var channelName = ob.channelName;
          var textureObjectDefaults = ob.textureObjectDefaults;
          var channelObjectDefaults = ob.channelObjectDefaults;
          var materialObjectRef = classScope.getMaterialObject(materialName);
          if (materialObjectRef !== null && materialObjectRef !== undefined) {
            var channelObjectRef = classScope.getChannelObject(
              materialObjectRef,
              channelName
            );
            if (channelObjectRef !== null && channelObjectRef !== undefined) {
              //remove texture
              if (uid === "") {
                //add color if it does not exist
                if (
                  channelObjectRef.color === null ||
                  channelObjectRef.color === undefined
                ) {
                  classScope.setColor(
                    materialName,
                    channelName,
                    null,
                    "#ffffff"
                  );
                }

                channelObjectRef.texture = null;
                delete channelObjectRef.texture;
                classScope.api.setMaterial(materialObjectRef);
              } else {
                //remove color if it exists
                if (channelObjectRef.color) {
                  channelObjectRef.color = null;
                  delete channelObjectRef.color;
                }

                //this is the cache of the original texture
                if (
                  channelObjectRef.textureIsCached === undefined ||
                  channelObjectRef.textureIsCached === null
                ) {
                  channelObjectRef.textureIsCached = true;
                  channelObjectRef.textureCached = channelObjectRef.texture;
                }

                //this is to add channel object defaults
                if (
                  channelObjectDefaults !== null &&
                  channelObjectDefaults !== undefined
                ) {
                  classScope.setChannelPropertiesActual(
                    channelObjectRef,
                    channelObjectDefaults
                  );
                }

                //if no texture property exists , create one , if it does exist, copy it
                var texob = {};
                var prop = null;
                if (
                  channelObjectRef.textureCached === null ||
                  channelObjectRef.textureCached === undefined
                ) {
                  texob = {};
                  texob.internalFormat = "RGB";
                  texob.magFilter = "LINEAR";
                  texob.minFilter = "LINEAR_MIPMAP_LINEAR";
                  texob.texCoordUnit = 0;
                  texob.textureTarget = "TEXTURE_2D";
                  texob.uid = 0; // not actual value , the uid still needs to be returned from a succcessful texture upload.
                  texob.wrapS = "REPEAT";
                  texob.wrapT = "REPEAT";
                } else {
                  //deep copy
                  for (prop in channelObjectRef.textureCached) {
                    texob[prop] = channelObjectRef.textureCached[prop];
                  }
                }

                channelObjectRef.texture = texob;

                //this is to add texture object defaults
                if (
                  textureObjectDefaults !== null &&
                  textureObjectDefaults !== null
                ) {
                  classScope.setTexturePropertiesActual(
                    channelObjectRef,
                    textureObjectDefaults
                  );
                }

                channelObjectRef.texture.uid = uid;
                classScope.api.setMaterial(materialObjectRef);
              }
            }
          }
        }

        classScope.materialsUIDPending[UIDKey] = null;
        storage = null;
        delete classScope.materialsUIDPending[UIDKey];

        classScope.dispatchEvent(classScope.EVENT_TEXTURE_APPLIED, UIDKey);
      }
    }
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.addTexture = function (url, UIDKey, useCaching) {
    classScope.api.getTextureList(function (err, textures) {
      if (err) {
        window.console.log(textures);
      }
    });

    useCaching = useCaching || false;
    if (UIDKey === null || UIDKey === undefined || UIDKey === "") {
      console.error(
        'a call to "addTexture" has been aborted. The argument UIDKey must have a valid string value so this texture has a means to be looked up at a later point'
      );
      return;
    }

    if (useCaching) {
      if (
        classScope.textureCache[UIDKey] !== null &&
        classScope.textureCache[UIDKey] !== undefined
      ) {
        if (classScope.enableDebugLogging) {
          console.log(
            'a call to addTexture found an existing textureCache for UIDKey "' +
              UIDKey +
              '", applyMaterialUIDPending called immediately.'
          );
        }
        classScope.applyMaterialUIDPending(UIDKey);
        return;
      }
    }

    /*   function addTextureCallback(err, uid) {
      if (uid !== undefined) {
        console.log("Replaced texture with UID", uid);
        classScope.textureCache[UIDKey] = uid;
        classScope.applyMaterialUIDPending(UIDKey);
        classScope.dispatchEvent(classScope.EVENT_TEXTURE_LOADED, {
          UIDKey: UIDKey,
        });
      } else {
        console.log(err);
      }
    } */

    // validate if the uid exists and if so rather use update texture , otherwise use addTexture
    //console.log("Key", UIDKey, classScope.textureCache[UIDKey]);
    if (
      //classScope.textureCache[UIDKey] !== null &&
      //classScope.textureCache[UIDKey] !== undefined
      UIDKey !== null &&
      UIDKey !== undefined
    ) {
      //console.log("updatetexture", url, classScope.textureCache[UIDKey]);
      classScope.api.updateTexture(url, UIDKey, function name(err, textureUid) {
        if (!err) {
          window.console.log("Replaced texture with UID", textureUid);
        } else {
          console.error(err);
        }
      });
    } else {
      console.log("uid undefined");
      //classScope.api.addTexture(url, addTextureCallback);
    }
  };

  //--------------------------------------------------------------------------------------------------------------------------

  this.getScreenShot = function (image) {
    classScope.api.getScreenShot("image/png", function (err, result) {
      if (!err) {
        image(result);
      }
    });
  };
}
