import React, { useEffect, useRef } from "react";

import { SketchfabApiUtils } from "./utils";

import "./component.css";

const useSketchfabViewer = (modelId, onModelReady, onViewerInit) => {
  // This ref will contain the actual iframe object
  const viewerIframeRef = useRef(null);

  const ViewerIframe = (
    <iframe
      // We feed the ref to the iframe component to get the underlying DOM object
      ref={viewerIframeRef}
      title="sketchfab-viewer"
      style={{
        height: "100%",
        width: "100%",
        padding: "0",
        margin: "0",
        display: "block",
      }}
      id="api-frame"
      /*sandbox="allow-scripts allow-same-origin"*/
      allow="autoplay; fullscreen; xr-spatial-tracking"
      xr-spatial-tracking="true"
      execution-while-out-of-viewport="true"
      execution-while-not-rendered="true"
      web-share="true"
      allowFullScreen
      /*mozallowfullscreen="true"
      webkitallowfullscreen="true"*/
    />
  );

  useEffect(
    () => {
      /*
      const onClientError = function () {
        console.error(
          'a call to "init()" on the sketchfab client object has failed'
        );
      };

      const onClientInit = function (api) {
        onViewerInit(new SketchfabApiUtils(api));

        api.addEventListener("viewerready", () => {
          onModelReady();
        });
      };
*/
      if (modelId) {
        const client = new window.Sketchfab(viewerIframeRef.current);
        // Initialize the viewer
        let apiUtils = new SketchfabApiUtils(modelId, client, onModelReady);
        apiUtils.create();
        onViewerInit(apiUtils);
      }
    },

    // We only want to initialize the viewer on first load
    // so we don't add any dependencies to useEffect
    [modelId, onModelReady, onViewerInit]
  );

  return [ViewerIframe];
};

export const Viewer = ({ model, onViewerInit, onModelReady }) => {
  //console.log("Viewer Render modelId", model);
  const [ViewerIframe] = useSketchfabViewer(model, onModelReady, onViewerInit);
  return <div className="viewer">{ViewerIframe}</div>;
};
