import React from "react";

import i18n from "../../i18n";
import { ASSET_URL, REDIRECT_URL } from "../../constants";

import "./component.css";

export const ConfiguratorHeader = (props) => {
  const lngs = {
    en: { nativeName: "EN" },
    it: { nativeName: "IT" },
  };

  //console.log("asset", ASSET_URL);
  //console.log("redirect", REDIRECT_URL);
  return (
    <header className="header">
      <nav className="navbar">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <div>
            <a className="nav-link" href={REDIRECT_URL}>
              <img
                src={`${ASSET_URL}/company-logo.png`}
                className="logo logo-header"
                alt="Company logo"
              ></img>
            </a>
          </div>
          <div>
            {Object.keys(lngs).map((lng) => (
              <span
                className={
                  lng !== props.currentLanguage
                    ? "language-selector"
                    : "language-selector__active"
                }
                key={lng}
                type="submit"
                onClick={() => {
                  i18n.changeLanguage(lng);
                  props.onClick(lng);
                }}
              >
                {lngs[lng].nativeName}
              </span>
            ))}
          </div>
        </div>
      </nav>
    </header>
  );
};
