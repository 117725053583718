export const REDIRECT_URL =
  process.env.REACT_APP_MS_REDIRECT_URL !== undefined
    ? process.env.REACT_APP_MS_REDIRECT_URL
    : `https://www.missoun.com`;

export const ASSET_URL =
  process.env.REACT_APP_MS_ASSET_URL !== undefined
    ? process.env.REACT_APP_MS_ASSET_URL
    : `https://d18zp8aace0t2d.cloudfront.net`;

//console.log(process.env.REACT_APP_MS_REDIRECT_URL);
//console.log(process.env.REACT_APP_MS_ASSET_URL);
