import React from "react";
import "./Attribute.css";

const Attribute = ({ option, onOptionSelectionChange }) => {
  const clickable = (status) => {
    if (
      status === "Initial" ||
      status === "Excluded" ||
      status === "Required" ||
      status === "Available" ||
      status === "Selected"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <article
      className={option?.status}
      {...(clickable(option?.status) && {
        onClick: () => onOptionSelectionChange(option.id),
      })}
    >
      <div className="attribute-header">
        <span className="title">{option?.name}</span>
        <span className="list-price">
          <span className="amount">{option?.listPrice[0]?.amount}</span>
          <span className="currency">{option?.listPrice[0]?.currency}</span>
        </span>
      </div>
      <div className="attribute-description">
        <p>{option?.desc}</p>
      </div>
    </article>
  );
};

export default Attribute;
