import i18n from "../../i18n";
import "./component.css";

export const ProductPrice = (prod) => {
  return (
    <div className="product-price d-flex flex-row justify-content-between px-3">
      <span>{i18n.t("price")}</span>
      <div>
        <span className="product-price__amount">
          {prod.prod?.totalAmount} {prod.prod?.listPrice[0]?.currency}
        </span>
      </div>
    </div>
  );
};
