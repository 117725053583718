import * as React from "react";
import { useEffect, useState } from "react";

import ProgressBar from "react-bootstrap/ProgressBar";
import { useInterval } from "./useInterval";
import { randomInt, randomValue } from "./utils";

import { useRef } from "react";

import "./component.css";

export const ProductLoading = (props) => {
  const [localProgress, setLocalProgress] = useState(0);
  const delay = useRef(props.refreshRate);

  useEffect(() => {
    if (delay.current) {
      setLocalProgress(randomInt(10, 20));
    }
  }, []);

  useInterval(() => {
    if (delay.current) {
      const minValue = Math.min(10, (100 - localProgress) / 8);
      const maxValue = Math.min(20, (100 - localProgress) / 5);
      const random = randomValue(minValue, maxValue);

      if (localProgress + random < 98) {
        setLocalProgress(localProgress + random);
      }
    }
  }, delay.current);

  const assetUrl =
    process.env.REACT_APP_MS_ASSET_URL !== undefined
      ? process.env.REACT_APP_MS_ASSET_URL
      : `https://d18zp8aace0t2d.cloudfront.net`;
  return (
    <div
      className="container-fluid configurator-loading"
      style={{
        height: "100vh",
        width: "100%",
        zindex: 1000,
        backgroundColor: "rgb(var(--color-bg))",
        position: "fixed",
        top: 0,
        right: 0,
      }}
    >
      <div className="row h-75 align-content-center">
        <div>
          <div className="col d-flex flex-column align-items-center pt-5">
            <img
              className="configurator-loading__clogo"
              src={`${assetUrl}/company-logo.png`}
              alt="Logo"
            />
            <span className="configurator-loading__cname">Missoun</span>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <span>{props.message}</span>
          <ProgressBar now={localProgress} />
          <span className="configurator-loading__poweredby">
            powered by Missoun
          </span>
        </div>
      </div>
    </div>
  );
};
