/*
export const setProductDefaultState = (product) => {
  const prod = structuredClone(product);
  if (prod !== undefined) {
    for (let i = 0; i < prod.feats.length; i++) {
      for (let j = 0; j < prod.feats[i].opts.length; j++) {
        prod.feats[i].opts[j].status = product.feats[i].opts[j].isDefault
          ? "Selected"
          : "Initial";
      }
    }
  }
  return prod;
};

export const updateProductState = (product, configuration) => {
  const prod = structuredClone(product);
  let optPrices = [];
  if (configuration !== undefined && prod !== undefined) {
    configuration.features.forEach((feat) => {
      const prodFeat = prod.feats.find((f) => f.id === feat.code);
      prodFeat.state = feat.state;
      feat.options.forEach((opt) => {
        const prodOpt = prodFeat.opts.find((o) => o.id === opt.code);
        prodOpt.status = opt.state;
        if (prodOpt.listPrice.length && opt.state === "Selected")
          optPrices.push(prodOpt.listPrice[0].amount);
      });
    });

    if (prod.priceOn) {
      prod.totalAmount = optPrices.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        prod?.listPrice[0]?.amount
      );
    }
  }
  return prod;
};

export const updateConfigurationState = (
  configuration,
  optCode,
  optSel = true
) => {
  const config = structuredClone(configuration);
  if (config !== undefined && optCode !== undefined) {
    config.features.forEach((f, i) => {
      const opt = f.options.find((o) => o.code === optCode);
      if (opt !== undefined) {
        for (let m = 0; m < config.features[i].options.length; m++) {
          if (config.features[i].options[m].code === optCode) {
            // set to select the option picked by the user
            config.features[i].options[m].state = optSel
              ? "Selected"
              : "Initial";
          } else if (config.features[i].isSingleChoice) {
            //set to initial if the feature is single choice
            config.features[i].options[m].state = "Initial";
          }
        }
      }
    });
  }
  return config;
};

export const createConfiguration = (product, configuration) => {
  const config = structuredClone(configuration);
  if (config !== undefined && product !== undefined) {
    product.feats.forEach((feat) => {
      const cFeature = config.features.find((f) => f.code === feat.id);
      feat.opts.forEach((opt) => {
        const cOption = cFeature.options.find((o) => o.code === opt.id);
        cOption.state = opt.status;
      });
    });
  }
  return config;
};
*/
export const changeNodeVisibility = (apiRef, options) => {
  let ids = [];
  options.forEach((option) => {
    if (option.status === "Selected") apiRef.current.showNode(option.nodeId);
    else if (option.nodeId !== 0) ids.push(option.nodeId);
  });
  ids.forEach((id) => apiRef.current.hideNode(id));
};

export const changeMaterialColor = (apiRef, feature) => {
  const option = feature.opts.find((o) => o.status === "Selected");
  if (option !== undefined && option !== null) {
    apiRef.current.setColor(
      feature.materialId,
      apiRef.current.AlbedoPBR,
      "color",
      option.color,
      false
    );
  }
};

export const changeModel = (apiRef, feature) => {
  feature.opts.forEach((option) => {
    option.actions.forEach((action) => {
      if (action.id === "SetNode") {
        option.status === "Selected"
          ? apiRef.current.showNode(action.targetValue)
          : apiRef.current.hideNode(action.targetValue);
      } else if (action.id === "SetColor" && option.status === "Selected") {
        apiRef.current.setColor(
          action.assetId,
          apiRef.current.AlbedoPBR,
          "color",
          action.targetValue,
          false
        );
      } else if (action.id === "SetTexture" && option.status === "Selected") {
        //console.log("Texture", action.targetValue, action.assetId);
        apiRef.current.addTexture(action.targetValue, action.assetId, false);
      }
    });
  });
};
