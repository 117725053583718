import React, { useEffect, useRef } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { getConfiguration } from "../../model/configuration";
import { getProduct, updateProductState } from "../../model/product";
import { ProductLoading } from "../ProductLoading";

import { REDIRECT_URL } from "../../constants";

export const ConfigurationRedirect = () => {
  const params = useParams();
  const configurationId = params?.configurationId;

  const nav = useRef(useNavigate());

  //TODO create a custom hook
  useEffect(() => {
    const loadConfiguration = async (quoteId) => {
      try {
        const config = await getConfiguration(quoteId);
        const prod = await getProduct(config.productId);

        const upProd = updateProductState(prod, config);
        //Navigate to configurator
        nav.current("/myconfiguration", {
          state: {
            product: upProd,
            configuration: config,
          },
        });
      } catch (error) {
        window.location.href = REDIRECT_URL;
      }
    };

    if (configurationId !== undefined) loadConfiguration(configurationId);
    else console.log("configuration undefined");
  }, [configurationId, nav]);

  return <ProductLoading refreshRate={0} message={"Searching..."} />;
};
