import React from "react";
import Attribute from "./Attribute";
import "./AttributeSelector.css";

export const AttributeSelector = ({ feature, onOptionSelectionChange }) => {
  return (
    <div className="attribute-selector__options">
      {feature?.opts.map((option) => {
        return (
          <Attribute
            key={option.id}
            option={option}
            onOptionSelectionChange={onOptionSelectionChange}
          />
        );
      })}
    </div>
  );
};
