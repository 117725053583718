import {
  urlGetConfiguration,
  urlPostConfiguration,
} from "../helpers/apis-urls";
import { fetchData, HttpVerb } from "./utils";

export async function getConfiguration(id) {
  const config = await fetchData(urlGetConfiguration(id), HttpVerb.GET);
  return Promise.resolve(config);
}

export async function newConfiguration(product) {
  const config = await fetchData(
    urlPostConfiguration(product.id),
    HttpVerb.POST,
    {}
  );
  return Promise.resolve(initializeConfiguration(config, product));
}

const initializeConfiguration = (configuration, product) => {
  const config = structuredClone(configuration);
  if (config !== undefined && product !== undefined) {
    product.feats.forEach((feat) => {
      const cFeature = config.features.find((f) => f.code === feat.id);
      feat.opts.forEach((opt) => {
        const cOption = cFeature.options.find((o) => o.code === opt.id);
        cOption.state = opt.status;
      });
    });
  }
  return config;
};

export const updateConfigurationState = (
  configuration,
  optCode,
  optSel = true
) => {
  const config = structuredClone(configuration);
  if (config !== undefined && optCode !== undefined) {
    config.features.forEach((f, i) => {
      const opt = f.options.find((o) => o.code === optCode);
      if (opt !== undefined) {
        for (let m = 0; m < config.features[i].options.length; m++) {
          if (config.features[i].options[m].code === optCode) {
            // set to select the option picked by the user
            config.features[i].options[m].state = optSel
              ? "Selected"
              : "Initial";
          } else if (config.features[i].isSingleChoice) {
            //set to initial if the feature is single choice
            config.features[i].options[m].state = "Initial";
          }
        }
      }
    });
  }
  return config;
};
