import React from "react";
import in18 from "../../i18n";

import "./component.css";

export const ProductFooterActions = ({ onBack, onReset, onSave, feats }) => {
  const error =
    feats.findIndex((feature) => feature.state === "SelectedWithError") !== -1;
  return (
    <div className="container-fluid h-100">
      <div className="row h-100 align-items-center px-2">
        <div className="col-4 px-2">
          <button
            onClick={onReset}
            className="w-100 ms-button ms-button--secondary"
          >
            {in18.t("reset")}
          </button>
        </div>
        <div className="col-8 px-2">
          <button
            onClick={onSave}
            className={`w-100  ${
              error ? "feature-error" : ""
            } ms-button ms-button--primary`}
          >
            {in18.t("save")}
          </button>
        </div>
      </div>
    </div>
  );
};

/*

<div className="col-3 px-2">
          <button
            onClick={onBack}
            className="w-100 ms-button ms-button--secondary"
          >
            Back
          </button>
        </div>
*/
