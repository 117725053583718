import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import i18n from "../../i18n";
import { ConfigurationLoader } from "../ConfigurationLoader/component";
import { ConfiguratorHeader } from "../ConfiguratorHeader";

import { ProductSlider } from "../ProductSlider/index";

import "./component.css";

export const ProductGroup = () => {
  const params = useParams();
  const [productsGroup, setProductsGroup] = useState(null);
  //const [quoteId, setQuoteId] = useState(null);
  const productGroupId = params.productGroupId;

  const [currentLanguage, setcurrentLanguage] = useState(i18n.language);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      let url =
        process.env.REACT_APP_MS_QUOTE_API !== undefined
          ? process.env.REACT_APP_MS_QUOTE_API
          : `https://dev-missoun.api.configurator.dev.missoun.com/v1`;
      url += `/products-groups/${productGroupId}`;
      //Get product
      const res = await fetch(url);

      let productsGroup = await res.json();
      setProductsGroup(productsGroup);
    };

    if (productGroupId !== null) {
      fetchData().catch((e) => {
        console.error(e);
      });
    }
  }, [productGroupId]);
  /*
  const handleQuoteInputOnChange = (event) => {
    const value = event.target.value;
    setQuoteId(value);
    console.log(value);
  };

  const handleQuoteInputOnClick = () => {
    const url = "/configurations/" + quoteId;
    navigate(url);
  };
*/
  const handleClickSelect = (product) => {
    navigate(`/products/${product.id}`);
    //console.log("navigate", product);
  };

  const handleLanguageClick = (lng) => {
    setcurrentLanguage(lng);
  };

  if (productsGroup !== undefined && productsGroup !== null) {
    return (
      <React.StrictMode>
        <ConfiguratorHeader
          currentLanguage={currentLanguage}
          onClick={handleLanguageClick}
        />
        <div className="container-fluid vh-100">
          <div className="row">
            <div className="col product-group">
              <ProductSlider
                group={productsGroup}
                handleClickSelect={handleClickSelect}
              />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xs-12 col-sm-8 col-md-6 col-lg-6">
              <ConfigurationLoader />
            </div>
          </div>
        </div>
      </React.StrictMode>
    );
  }
  return null;
};
