import React from "react";
import "./Color.css";

export const Color = ({ option, onOptionSelectionChange }) => {
  const backgroundColor = () => {
    const bgColor = option?.color;
    let backgroundStyle;
    if (option?.colorType === "HEX") {
      backgroundStyle = {
        backgroundColor: `${bgColor}`,
      };
    } else {
      backgroundStyle = {
        backgroundImage: `url("${bgColor}")`,
        backgroundPosition: "center",
      };
    }
    return backgroundStyle;
  };

  const clickable = (status) => {
    if (status === "Initial" || status === "Excluded") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="color-details col-3">
      <div
        className={`color-display ${option?.status}`}
        style={backgroundColor()}
        {...(clickable(option?.status) && {
          onClick: () => onOptionSelectionChange(option?.id),
        })}
      ></div>
      <div className="color-name">{option?.name}</div>
    </div>
  );
};
