import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { ProductFeaturesAccordion } from "../ProductFeaturesAccordion";
import { ProductHeader } from "../ProductHeader";
import { ProductFooterActions } from "../ProductFooterActions";
import { ProductPrice } from "../ProductPrice";
import { ContactForm } from "../ContactForm";

import { changeModel } from "./utils";
import { updateProductState } from "../../model/product";
import { updateConfigurationState } from "../../model/configuration";
import { urlPutConfiguration } from "../../helpers/apis-urls";

import "./component.css";

export const ProductConfiguration = ({
  prod,
  configuration,
  apiRef,
  viewerReady,
}) => {
  const defaultConfig = useRef(configuration);

  const [product, setProduct] = useState(prod);
  const [config, setConfig] = useState(null);
  const [latestConfig, setLatestConfiguration] = useState(configuration);

  const [reset, setReset] = useState(false);
  const [saveMode, setSaveMode] = useState(false);

  const navigate = useNavigate();

  //console.log("Render ProductConfiguration");

  //Reset to default configuration
  useEffect(() => {
    //console.log("reset config");
    if (defaultConfig && reset) setConfig(defaultConfig.current);
  }, [reset]);

  useEffect(() => {
    const updateViewer = (apiRef, features) => {
      features.forEach((feature) => {
        if (viewerReady) {
          changeModel(apiRef, feature);
        }
      });
    };
    if (product !== undefined && apiRef !== undefined) {
      if (apiRef.current !== null) {
        updateViewer(apiRef, product.feats);
      }
    }
  }, [product, apiRef, viewerReady]);

  useEffect(() => {
    const validateConfiguration = async (config) => {
      const url = urlPutConfiguration(config.productId, config.id);
      //const s = Date.now();
      //Create new configuration
      const res = await fetch(url, {
        method: "PUT",
        headers: { "content-Type": "application/json" },
        body: JSON.stringify(config),
      });
      const validatedConfig = await res.json();

      //console.log("validate configuration duration:", Date.now() - s, "ms");
      //console.log(validatedConfig);

      setConfig(validatedConfig);
    };

    if (latestConfig !== null) {
      validateConfiguration(latestConfig);
    }
  }, [latestConfig]);

  //Update product when user select an option
  useEffect(() => {
    //console.log("update product", config);
    if (config !== null) {
      setProduct((current) => {
        const p = updateProductState(current, config);
        return p;
      });
    }
  }, [config]);

  const handleOptionSelected = (option) => {
    const feature = product.feats.find(
      (feature) =>
        feature.opts.find(
          (opt) => opt.id === option && opt.status === "Selected"
        ) !== undefined
    );
    //console.log("handleOptionSelected", config);
    if (
      feature === undefined ||
      (feature !== undefined && !feature?.isSingle)
    ) {
      const sel = feature === undefined ? true : false;
      setLatestConfiguration(updateConfigurationState(config, option, sel));
    }
  };

  const handleBackClicked = () => {
    navigate("/");
  };

  const handleResetClicked = () => {
    const r = reset === true ? false : true;
    setReset(r);
  };

  const handleFormBackClicked = () => {
    setSaveMode(false);
  };

  const handleSaveClicked = () => {
    if (
      product.feats.findIndex(
        (feature) => feature.state === "SelectedWithError"
      ) === -1
    ) {
      setSaveMode(true);
    }
  };

  if (product !== null) {
    let productBody;
    if (!saveMode) {
      productBody = (
        <div className="product-configuration ms-scrollbar">
          <div className="product-configuration__panel">
            <div
              className={`row g-0 d-flex flex-column ms-scrollbar product-features ${
                product.priceOn
                  ? "product-features__withprice"
                  : "product-features__withoutprice"
              }`}
            >
              <div className="col product-features__col">
                <ProductFeaturesAccordion
                  features={product.feats}
                  onOptionSelectionChange={handleOptionSelected}
                />
              </div>
            </div>
            {product.priceOn && <ProductPrice prod={product} />}
            <div className="row g-0 ms-panel-footer">
              <ProductFooterActions
                onBack={handleBackClicked}
                onReset={handleResetClicked}
                onSave={handleSaveClicked}
                feats={product.feats}
              />
            </div>
          </div>
        </div>
      );
    } else {
      productBody = (
        <ContactForm
          configuration={config}
          onCancel={handleFormBackClicked}
          apiRef={apiRef}
        />
      );
    }
    return (
      <div className="container-fluid px-0 selector">
        <div className="row g-0">
          <ProductHeader name={product.name} />
        </div>
        {productBody}
      </div>
    );
  } else return <div>please retry using a different product</div>;
};
