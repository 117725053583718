import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          //Actions
          save: "Save",
          reset: "Reset",
          back: "Back",
          load: "Load",
          select: "Select",
          close: "Close",

          //Form
          name: "First name",
          surname: "Last name",
          email: "Email",
          telephone: "Telephone",
          city: "City",
          country: "Country",
          agree: "I agree",
          insertCode: "Insert your configuration code",

          //States
          loading: "Loading...",
          searching: "Searching...",
          choose: "Choose...",
          codeNotValid: "The configuration code is not valid.",
          required: "(required)",
          optional: "(optional)",
          requiredField: "This field is required",
          invalidField: "This field is invalid",
          selectionError: "The selected option is incompatible",
          termsError: "Accept the terms and condition before proceeding",

          //General
          price: "Price",

          configModalHeader: "Your configuration",
          configModalbody:
            "Click on the copy button to recover the code of your configuration, or download the pdf.",

          pdfDownload: "Download PDF",
          code: "Code",
          copied: "Copied",
          configCode: "GerrisBoats code",
          creating: "Loading",
          download: "Download",

          //Modal
          terms: "Terms and Conditions",
          termsText: "Saving the configuration, I accept the ",
          termsHref: "https://www.gerrisboats.com/privacy-policy/",

          privacy: "Privacy Policy",
          privacyText:
            "Send me information about new products, services, deals and reccomendation. For further details, review our ",
          privacyHref: "https://www.gerrisboats.com/privacy-policy/",

          redirectHref: "https://gerrisboats.com/",

          //PDF
          yourConfig: "La tua configurazione",
          totPrice: "Total: ",
          configLink:
            "Direct link: https://gerrisboats.configurator.missoun.com/configurations/",
          footerAddress: "Gerrisboats Via Moggia, 75, 16033 Lavagna GE, Italy",
          footerTel: "T. +39 0185 323037",
          footerEmail: "E. info@gerrisBoats.com",
          disclaimer:
            "This configuration does not constitute an offer by GerrisBoats but an invitation to submit an order request. The colors displayed are only representative of the product and further " +
            "may differ according to the type of device used. The prices and components indicated will be confirmed by GerrisBoats once an order request has been received from the Customer. For" +
            " all other conditions of sale, consult the complete document on the site at the link https://www.gerrisboats.com/privacy-policy/",
        },
      },
      it: {
        translation: {
          //Actions
          save: "Salva",
          reset: "Ripristina",
          back: "Indietro",
          load: "Carica",
          select: "Seleziona",
          close: "Chiudi",

          //Form
          name: "Nome",
          surname: "Cognome",
          email: "Email",
          telephone: "Telefono",
          city: "Città",
          country: "Nazione",
          agree: "Acconsento",
          insertCode: "Inserisci il tuo codice di configurazione",

          //States
          loading: "Caricamento...",
          searching: "Ricerca...",
          choose: "Seleziona...",
          codeNotValid: "Il codice di configurazione non è valido.",
          required: "(obbligatorio)",
          optional: "(opzionale)",
          requiredField: "Questo campo è obbligatorio",
          invalidField: "Questo campo non è valido",
          selectionError: "L'opzione selezionata è incompatibile",
          termsError: "Accetta i Termini e Condizioni prima di procedere",

          //General
          price: "Prezzo",

          //Modal
          configModalHeader: "La tua configurazione",
          configModalbody:
            "Clicca sul tasto copia per recuperare il codice della tua configurazione, oppure scarica il pdf.",

          pdfDownload: "Scarica PDF",
          code: "Codice",
          copied: "Copiato",
          configCode: "Codice Gerrisboats",
          creating: "Caricamento",
          download: "Scarica",

          //Message

          terms: "Termini e Condizioni",
          termsText: "Salvando la configurazione, accetto i ",
          termsHref: "https://www.gerrisboats.com/privacy-policy/",

          privacy: "Privacy Policy",
          privacyText:
            "Desidero ricevere informazioni, suggerimenti e offerte su prodotti e offerte. Per maggiori dettagli, consulta la nostra ",
          privacyHref: "https://www.gerrisboats.com/privacy-policy/",

          redirectHref: "https://www.gerrisboats.com/",

          //PDF
          yourConfig: "La tua configurazione",
          totPrice: "Prezzo totale: ",
          configLink:
            "Link diretto: https://gerrisboats.configurator.missoun.com/configurations/",

          footerAddress: "Gerrisboats Via Moggia, 75, 16033 Lavagna GE, Italia",
          footerTel: "T. +31 06 50039548",
          footerEmail: "E. info@gerrisboats.com",
          disclaimer:
            "La presente configurazione non costituisce un’offerta da parte di Gerrisboats ma un invito a presentare una richiesta di ordine. " +
            "I colori visualizzati sono solo rappresentativi del prodotto e inoltre potrebbero differire in base al tipo di dispositivo utilizzato. I prezzi ed i componenti indicati verranno confermati da GerrisBoats una volta ricevuta una richiesta di ordine da parte del Cliente. " +
            "Per tutte le altre condizioni di vendita consultare il documento completo presente sul sito al link " +
            "https://www.gerrisboats.com/privacy-policy/",
        },
      },
    },
  });

export default i18n;
